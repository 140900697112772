import { useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import VideoStreamMerger from 'video-stream-merger';
import RecordRTC, { WhammyRecorder } from 'recordrtc';

import {
  AUDIO_MODE,
  BASE_STREAM_CONFIG,
  CAMERA_MODE,
  CAMERA_SCREEN_MODE,
  getBaseConfig,
  getOrientation,
  getScreenShare,
  IS_MOBILE,
  logoConfig,
  MERGER_CONFIG,
  mirrorConfig,
  ONE_SECOND,
  RECORD_EVENT,
  SCREEN_MODE,
  stopTracks,
} from '../../../utils';
import RecorderVideoButton from './Button';
import RecorderVideoTime from './Time';
import RecorderVideoTimer from './Timer';
import UIWave from '../../UI/Wave';
import RecorderVideoControlBar from './ControlBar';

import style from './index.module.scss';
import RecorderVideoRedoModal from './RedoModal';

const isAvailableMerger = () => {
  try {
    new VideoStreamMerger();

    return true;
  } catch {
    return false;
  }
};

const checkSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
};

const isSupportMerger = isAvailableMerger();
const isSafari = checkSafari();

const RecorderVideo = (props) => {
  const {
    timeLength,
    countdownTimer,
    branding: { logoImageUrl },
    onSubscribe,
    stream,
    mode,
    file,
    setFile,
    visibility: { pauseButton, timeLeft, stopButton },
  } = props;

  const refVideo = useRef();
  const refFullBlock = useRef();

  const [isPlay, setPlay] = useState(true);

  const [recStatus, setRecStatus] = useState('stop');
  const [timer, setTimer] = useState(countdownTimer);
  const [isTimerActive, setTimerActive] = useState(false);
  const [stopTimer, setStopTimer] = useState(timeLength);

  const [mediaRecorder, setMediaRecoder] = useState();

  const [merger, setMerger] = useState();

  const [startTime, setStartTime] = useState(new Date());
  const [initialTime, setInitialTime] = useState(0);
  const [deltaTime, setDeltaTime] = useState(0);

  const [screenStream, setScreenStream] = useState();

  const [currentTime, setCurrentTime] = useState(0);
  const [endTime, setEndTime] = useState(1);

  const [isShowModal, setShowModal] = useState(false);

  const [isFullscreen, setFullscreen] = useState(false);

  const recPauseBtn = pauseButton && recStatus === 'rec' ? 'pause' : 'rec';

  const fullscreenHandler = useCallback(() => {
    setFullscreen(!isFullscreen);
  }, [isFullscreen, setFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', fullscreenHandler);

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenHandler);
    };
  }, [fullscreenHandler]);

  useEffect(() => {
    return () => {
      stopTracks(screenStream);
    };
  }, [screenStream, mode]);

  useEffect(() => {
    return () => {
      merger?.destroy();
    };
  }, [merger]);

  const addAndPlayVideo = async (stream, { muted }) => {
    if (refVideo?.current) {
      if (typeof stream === 'string') {
        refVideo.current.src = stream;
        refVideo.current.muted = muted;
        refVideo.current.srcObject = null;
      } else {
        refVideo.current.src = '';
        refVideo.current.muted = muted;
        refVideo.current.srcObject = stream;
      }
      await refVideo.current.play();
    }
  };

  const resizeWindow = async () => {
    if (stream) {
      await getStreamConfig(stream);
    }
  };

  const initStream = useCallback(
    async (stream) => {
      const playConfig = { muted: true };

      if (isSupportMerger) {
        const streamConfig = await getStreamConfig(
          stream,
          mode === CAMERA_SCREEN_MODE,
        );

        const merger = new VideoStreamMerger(streamConfig);

        const img = new Image();
        img.src = logoImageUrl;

        switch (mode) {
          case AUDIO_MODE:
            break;
          case SCREEN_MODE:
          case CAMERA_SCREEN_MODE:
            const streamFromScreenShare = await getScreenShare();
            setScreenStream(streamFromScreenShare);
            const config = await getStreamConfig(streamFromScreenShare);

            merger.setOutputSize(config.width, config.height);
            // Add the screen capture. Position it to fill the whole stream (the default)
            merger.addStream(streamFromScreenShare, {
              ...BASE_STREAM_CONFIG,
              mute: true, // we don't want sound from the screen (if there is any)
            });

            if (mode === CAMERA_SCREEN_MODE) {
              merger.addStream(stream, {
                ...mirrorConfig(
                  streamConfig,
                  MERGER_CONFIG.height - streamConfig.height,
                  true,
                ),
              });
            } else {
              merger.addStream(stream, {
                height: 0,
                width: 0,
              });
            }
            break;
          case CAMERA_MODE:
          default:
            merger.addStream(stream, mirrorConfig(streamConfig));
            break;
        }
        merger.addStream('logo', logoConfig(streamConfig, img));

        merger.start();
        setMerger(merger);
        await addAndPlayVideo(merger.result, playConfig);
      } else {
        await addAndPlayVideo(stream, playConfig);
      }
    },
    [mode],
  );

  useEffect(() => {
    if (file) {
      try {
        addAndPlayVideo(URL.createObjectURL(file), {
          muted: false,
        });
      } catch (error) {
        onSubscribe(RECORD_EVENT.ASSET_PLAYBACK_FAILED, { error });
      }
    }
  }, [file]);

  useEffect(() => {
    if (stream) {
      initStream(stream);
      window.addEventListener('resize', resizeWindow);
    }

    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, [stream]);

  useEffect(() => {
    let stopTimerInterval;
    if (recStatus === 'rec') {
      if (stopTimer > 0) {
        stopTimerInterval = setInterval(() => {
          const reminder = (initialTime - stopTimer) * 1000;
          const deltaTime =
            reminder === 0
              ? 0
              : new Date().getTime() - startTime.getTime() - reminder;
          setDeltaTime(deltaTime);
          setStopTimer(stopTimer - 1);
        }, Math.max(0, ONE_SECOND - deltaTime));
      } else {
        handlerStopRecord();
      }
    }
    return () => {
      if (stopTimerInterval) {
        clearInterval(stopTimerInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopTimer, recStatus]);

  useEffect(() => {
    let interval;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, ONE_SECOND);
    } else {
      clearInterval(interval);
      setTimeout(() => {
        setTimerActive(false);
        setTimer(countdownTimer);
      }, 500);
      if (timer === 0 && merger) {
        startRecord(mode === AUDIO_MODE ? stream : merger.result);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, isTimerActive, merger, stream]);

  useEffect(() => {
    if (file) {
      onSubscribe(
        isPlay
          ? RECORD_EVENT.ASSET_PLAYBACK_STARTED
          : RECORD_EVENT.ASSET_PLAYBACK_PAUSED,
      );
    }
  }, [file, isPlay, onSubscribe]);

  const startRecord = async (stream) => {
    try {
      onSubscribe && onSubscribe(RECORD_EVENT.RECORD_ACTIVE);

      setRecStatus('rec');
      setStartTime(new Date());
      setStopTimer(timeLength);
      setInitialTime(timeLength);

      const initRecorder =
        mode === AUDIO_MODE
          ? {
              mimeType: 'audio/wav',
              type: 'audio',
            }
          : {
              mimeType: 'video/mp4',
              type: 'video',
            };

      const mediaRecorder = new RecordRTC(stream, {
        ...initRecorder,
        ...(isSafari && {
          recorderType: WhammyRecorder,
        }),
      });

      setMediaRecoder(mediaRecorder);

      mediaRecorder.startRecording();
    } catch (error) {
      onSubscribe(RECORD_EVENT.RECORD_FAILED, { error });
    }
  };

  const handlerRecord = async () => {
    if (recStatus === 'rec' && pauseButton) {
      setRecStatus('pause');
      mediaRecorder?.pauseRecording();
    } else if (recStatus === 'pause') {
      setRecStatus('rec');
      setStartTime(new Date());
      setInitialTime(stopTimer);
      mediaRecorder?.resumeRecording();
    } else if (recStatus === 'stop') {
      setTimerActive(true);
      setPlay(true);
    }
  };

  const handlerStopRecord = async () => {
    if (recStatus === 'rec' || recStatus === 'pause') {
      mediaRecorder?.stopRecording(() => {
        const blob = mediaRecorder?.getBlob();

        if (refVideo.current) {
          refVideo.current.srcObject = null;
        }

        setRecStatus('stop');
        addVideo(blob);
      });

      stopTracks(stream);
    } else {
      setShowModal(true);
    }
  };

  const addVideo = useCallback(
    (media) => {
      setFile(media);

      const assetLengthInSeconds =
        endTime === Infinity ? timeLength - stopTimer : endTime;

      onSubscribe &&
        onSubscribe(
          mode === AUDIO_MODE
            ? RECORD_EVENT.AUDIO_RECORDED
            : RECORD_EVENT.VIDEO_RECORDED,
          {
            media,
            assetLengthInSeconds,
          },
        );
    },
    [endTime, mode, onSubscribe, setFile, stopTimer, timeLength],
  );

  const getStreamConfig = async (stream, isActiveScreenShare) => {
    const videoTracks = stream.getVideoTracks();

    if (!videoTracks.length) {
      return MERGER_CONFIG;
    }
    let cameraSettings = videoTracks[0].getSettings();

    const orientation = getOrientation();
    const aspectRatio = cameraSettings.aspectRatio || 1;

    const rotate =
      (IS_MOBILE && orientation === 'portrait' && aspectRatio > 1) ||
      (orientation === 'landscape' && aspectRatio < 1);

    if (rotate) {
      await videoTracks[0].applyConstraints({
        aspectRatio: 1 / (cameraSettings.aspectRatio || 1),
      });
    }

    cameraSettings = videoTracks[0].getSettings();

    const cameraWidth = cameraSettings.width
      ? cameraSettings.width <= MERGER_CONFIG.width
        ? cameraSettings.width
        : MERGER_CONFIG.width
      : MERGER_CONFIG.width;
    const cameraHeight = cameraSettings.height
      ? cameraSettings.height <= MERGER_CONFIG.height
        ? cameraSettings.height
        : MERGER_CONFIG.height
      : MERGER_CONFIG.height;

    const baseConfig = getBaseConfig(refVideo, isActiveScreenShare);

    const kWidth = baseConfig.width / cameraWidth;
    const kHeight = baseConfig.height / cameraHeight;

    const k =
      Math.min(kWidth, kHeight) *
      (isActiveScreenShare && isSupportMerger ? 0.3 : 1);

    const widthConfig = cameraWidth * k;
    const heightConfig = cameraHeight * k;

    if (isActiveScreenShare) {
      return {
        width: widthConfig,
        height: heightConfig,
        x: 0,
      };
    }

    return {
      width: cameraWidth,
      height: cameraHeight,
      x: (baseConfig.width - widthConfig) / 2,
    };
  };

  const timeUpdateHandler = (event) => {
    const EO = event.target;

    setCurrentTime(EO.currentTime);
  };

  const pauseHandler = () => {
    setPlay(false);
  };

  const playHandler = () => {
    setPlay(true);
  };

  const durationChangeHandler = () => {
    if (refVideo.current) {
      const duration = Math.round(refVideo.current.duration);
      setEndTime(duration);
    }
  };

  const changeInterval = (_event, value) => {
    setCurrentTime(value);
    if (refVideo.current) {
      refVideo.current.currentTime = value === Infinity ? 0 : value;
    }
  };

  const playPauseHandler = async () => {
    const newIsPlay = !isPlay;

    if (refVideo?.current) {
      if (newIsPlay) {
        await refVideo.current.play();
      } else {
        await refVideo.current.pause();
      }
    }

    setPlay(newIsPlay);
  };

  const redoCancelHandler = () => {
    setShowModal(false);
  };

  const redoApproveHandler = () => {
    addAndPlayVideo('', { muted: true });

    setStopTimer(timeLength);
    setFile();

    onSubscribe && onSubscribe(RECORD_EVENT.RECORD_RELOAD_STREAM);
    redoCancelHandler();
  };

  const changeFullscreenModeHandler = useCallback(() => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      const videoElem = refFullBlock.current;
      if (!videoElem) {
        return;
      }

      if (videoElem.requestFullscreen) {
        videoElem.requestFullscreen();
      } else if (videoElem.webkitRequestFullscreen) {
        /* Safari */
        videoElem.webkitRequestFullscreen();
      } else if (videoElem.msRequestFullscreen) {
        /* IE11 */
        videoElem.msRequestFullscreen();
      }
    }
  }, [isFullscreen, refFullBlock]);

  return (
    <div
      className={cn('camera--main w-full', style.video_container)}
      ref={refFullBlock}
    >
      <video
        ref={refVideo}
        className={cn('w-full', style.video)}
        controls={false}
        onTimeUpdate={timeUpdateHandler}
        onEnded={pauseHandler}
        onPlay={playHandler}
        onPause={pauseHandler}
        onDurationChange={durationChangeHandler}
      />
      {!!file && (
        <RecorderVideoControlBar
          onChange={changeInterval}
          currentTime={currentTime}
          endTime={endTime}
          isPlay={isPlay}
          playPauseHandler={playPauseHandler}
          refVideo={refVideo}
          isFullscreen={isFullscreen}
          changeFullscreenModeHandler={changeFullscreenModeHandler}
        />
      )}
      {mode === AUDIO_MODE && (
        <UIWave source={file} isPlay={isPlay} currentTime={currentTime} />
      )}
      <div className={cn(style.controls, file && style.redo)}>
        {!file && (
          <RecorderVideoButton
            icon={recPauseBtn}
            onClick={handlerRecord}
            key="rec"
            name={recPauseBtn.toUpperCase()}
          />
        )}
        {((stopButton && recStatus !== 'stop') || file) && (
          <RecorderVideoButton
            icon={file ? 'redo' : 'stop'}
            onClick={handlerStopRecord}
            key="stop"
            name={file ? 'Re-Record' : 'STOP'}
          />
        )}
      </div>

      <RecorderVideoTime
        timeLeft={timeLeft}
        file={file}
        recStatus={recStatus}
        stopTimer={stopTimer}
      />
      <RecorderVideoTimer isTimerActive={isTimerActive} timer={timer} />

      {isShowModal && (
        <RecorderVideoRedoModal
          handlerApprove={redoApproveHandler}
          handlerCancel={redoCancelHandler}
        />
      )}
    </div>
  );
};

export default RecorderVideo;
