import logoImg from '../../../assets/img/logo.svg';
import searchIsonImg from '../../../assets/img/searchicon.svg';
import pointsIconImg from '../../../assets/img/pointsicon.svg';
import linePointsImg from '../../../assets/img/linepoints.svg';
import notificationImg from '../../../assets/img/notification.svg';
import personImageImg from '../../../assets/img/personimage.png';
import pinImg from '../../../assets/img/pin.svg';
import profileHeaderImg from '../../../assets/img/profileheader.png';

const UIHeader = () => {
  return (
    <header className="colored__header">
      <div className="search__mobile">
        <div className="inner__search--mobile">
          <input
            type="text"
            placeholder="Search"
            className="w-full py-2 px-3 outline-none rounded pr-12"
          />
          <a href="#">
            <svg
              id="_1828778"
              data-name="1828778"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                id="Path"
                d="M7.1,6l4.673-4.673a.777.777,0,0,0-1.1-1.1L6,4.9,1.327.228a.777.777,0,0,0-1.1,1.1L4.9,6,.228,10.673a.777.777,0,1,0,1.1,1.1L6,7.1l4.673,4.673a.777.777,0,0,0,1.1-1.1Z"
                fill="#000"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className="container mx-auto px-4 ">
        <div className="outer__header flex items-center justify-between w-full py-3">
          <div className="header__left flex items-center justify-start w-full">
            <a
              href="#"
              className="inline-flex items-center justify-center pr-4 hover:opacity-70 transition-opacity"
            >
              <img src={logoImg} alt="logo" />
            </a>
            <div className="search__header hidden md:inline-flex lg:inline-flex">
              <form action="">
                <div className="search__field relative flex items-center ">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full py-2 px-3 outline-none rounded pr-12"
                  />
                  <input
                    type="submit"
                    className="absolute r-0 cursor-pointer hover:opacity-70 transition-opacity"
                    value=""
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="header__right flex items-center">
            <div className="small__search">
              <a href="#" className="rounded-lg">
                <img src={searchIsonImg} alt="searchicon" />
              </a>
            </div>
            <div className="points__header mr-3 ">
              <a
                href="#"
                className="flex items-center justify-center rounded-lg px-2 md:px-3 py-2"
              >
                <p className="m-0 inline-flex items-center whitespace-nowrap text-sm md:text-base">
                  <span className="inline-flex items-center mr-2 md:mr-2">
                    <img src={pointsIconImg} alt="pointsicon" />
                  </span>
                  <span className="hidden md:inline-flex">My points</span>
                </p>
                <img
                  src={linePointsImg}
                  alt="linepoints"
                  className=" hidden md:inline-flex mx-2 md:mx-2"
                />
                <span className="text-sm md:text-base">1050</span>
              </a>
            </div>
            <div className="notification__icon mr-3">
              <a
                href="#"
                className="inline-flex items-center justify-center rounded-lg"
              >
                <img src={notificationImg} alt="notification" />
              </a>
              <div className="notification__drop absolute bg-white  rounded">
                <div className="notification__head flex items-center justify-between pl-4 pr-4 pb-2 pt-4">
                  <p className="m-0 text-sm">Notifications</p>
                </div>
                <div className="notification__list pb-6">
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3 important__notification relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3 important__notification relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.{' '}
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3 important__notification relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3  relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start pb-0">
                    <div className="notification__person inline-flex mr-3  relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3 important__notification relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start">
                    <div className="notification__person inline-flex mr-3  relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="elem__notification px-4 py-4 flex items-start justify-start pb-0">
                    <div className="notification__person inline-flex mr-3  relative">
                      <img src={personImageImg} alt="personimage" />
                    </div>
                    <div className="notification__details">
                      <p className="text-xs m-0">
                        <span>Steve added</span> new content to Technology
                        /UX/Designing for conversion.
                      </p>
                      <div className="bottom__notification flex items-center justify-start">
                        <p className="m-0 text-xs">3 hours ago</p>
                        <span className="inline-flex mx-2">
                          <img src={pinImg} alt="ping" />
                        </span>
                        <a
                          href="#"
                          className="text--color text-sm  hover:opacity-70 transition-opacity"
                        >
                          View here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile__header flex items-center justify-center">
              <a href="#" className="profile__image">
                <img src={profileHeaderImg} alt="profileheader" />
              </a>
              <div className="profile__dropdown">
                <ul>
                  <li>
                    <a href="#" className="text-base">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      Settings
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      Billing
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      Manage Members
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      Payment Methods
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-base">
                      Sign Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UIHeader;
