import cn from 'classnames';

import style from './index.module.scss';

const RecorderVideoControlBarButton = (props) => {
  const { icon, className, onClick } = props;

  const icons = {
    pause: (
      <svg
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="4" height="20" rx="2" fill="#ffffff" />
        <rect x="8" width="4" height="20" rx="2" fill="#ffffff" />
      </svg>
    ),
    play: (
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.62003V18.38C0 19.658 1.3721 20.4345 2.42878 19.7389L15.2666 11.3589C16.2445 10.728 16.2445 9.27201 15.2666 8.62491L2.42878 0.261113C1.3721 -0.434522 0 0.342001 0 1.62003Z"
          fill="#ffffff"
        />
      </svg>
    ),
    smallscreen: (
      <svg width="20" height="20" viewBox="0 0 16 16">
        <path
          d="M14.7501 6.50006V5.00006H12.0607L15.2804 1.78039L14.2197 0.719727L11.0001 3.9394V1.25006H9.50007V6.50006H14.7501ZM4.25008 4.25004V8.00004H2.75008V2.75004H8.00008V4.25004H4.25008ZM11.7501 8.00024V11.7502H8.00008V13.2502H13.2501V8.00024H11.7501ZM1.78039 15.2804L5.00006 12.0607V14.75H6.50006V9.50003H1.25006V11H3.9394L0.719727 14.2197L1.78039 15.2804Z"
          fill="#ffffff"
        />
      </svg>
    ),
    fullscreen: (
      <svg width="20" height="20" viewBox="0 0 14 14">
        <path
          d="M1.4 1.4V7H0V0H7V1.4H1.4ZM9.59503 5.39497L12.6001 2.38995V4.9H14.0001V0H9.10005V1.4H11.6101L8.60508 4.40503L9.59503 5.39497ZM12.6 7V12.6H7V14H14V7H12.6ZM4.9 13.9991V12.5991H2.38995L5.39497 9.59412L4.40503 8.60417L1.4 11.6092V9.09914H0V13.9991H4.9Z"
          fill="#ffffff"
        />
      </svg>
    ),
  };

  return (
    <div onClick={onClick} className={cn(style.container, className)}>
      {icons[icon]}
    </div>
  );
};

export default RecorderVideoControlBarButton;
