import cn from 'classnames';

import Recorder from '../../components/Recorder';

import logoIconSvg from '../../assets/img/logo.svg';

import style from './index.module.scss';
import { RECORD_EVENT } from '../../utils';
import { StorageService } from '../../services';

const ViewRecorder = (props) => {
  const { setTumbnails } = props;

  const onSubscribeHandler = (event, data) => {
    console.log(event, data);

    if (event === RECORD_EVENT.TUMBNAILS_CREATED) {
      const tumbnails = StorageService.getTumbnails();
      setTumbnails(tumbnails);
    }
  };

  const options = {
    timeLength: 180,
    countdownTimer: 5,
    branding: { logoImageUrl: logoIconSvg },
    visibility: {
      pauseButton: true,
      screenShareButton: true,
      timeLeft: true,
      uploadButton: true,
      stopButton: true,
      sendButton: true,
    },
    onSubscribe: onSubscribeHandler,
    className: 'my_class',
    responseParameters: {
      userId: 1,
      channelId: 2,
      assetId: 3,
      cincopaAssetId: 4,
    },
  };

  return (
    <div className={cn('camera__block pt-6 md:pt-11', style.container)}>
      <div className={cn('container mx-auto px-4', style.wrapper)}>
        <Recorder {...options} />
      </div>
    </div>
  );
};

export default ViewRecorder;
