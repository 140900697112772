import Axios from 'axios';
import { CINCOPA_API_URL } from '../utils';

export const sendFile = (props) => {
  const { fileName, file, setProgress } = props;

  const uploadProgressHandler = (event) => {
    const { loaded, total } = event;

    if (total === 0) {
      return setProgress(0);
    }

    const percent = Math.floor((loaded * 100) / total);

    setProgress(percent === 100 ? 99 : percent);
  };

  const data = new FormData();

  data.append('file', file, fileName);
  return Axios({
    method: 'POST',
    url: CINCOPA_API_URL,
    onUploadProgress: uploadProgressHandler,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
