import cn from 'classnames';

import RecorderVideoControlBarButton from './Button';
import RecorderVideoControlBarSlider from './Slider';

import style from './index.module.scss';
import RecorderVideoControlBarSoundBar from './SoundBar';
import RecorderVideoControlBarTime from './Time';

const RecorderVideoControlBar = (props) => {
  const {
    duration,
    currentTime,
    endTime,
    isPlay,
    playPauseHandler,
    refVideo,
    isFullscreen,
    changeFullscreenModeHandler,
    ...sliderProps
  } = props;

  const curTime = Math.round(currentTime);

  return (
    <div className={style.container}>
      <div className={style.first_line}>
        <div className={style.slider}>
          <RecorderVideoControlBarSlider
            currentTime={curTime}
            endTime={endTime}
            {...sliderProps}
          />
        </div>
      </div>
      <div className={style.second_line}>
        <RecorderVideoControlBarButton
          icon={isPlay ? 'pause' : 'play'}
          className={style.button}
          onClick={playPauseHandler}
        />
        <RecorderVideoControlBarSoundBar
          className={style.sound_bar}
          refVideo={refVideo}
        />
        <RecorderVideoControlBarTime currentTime={curTime} endTime={endTime} />
        <RecorderVideoControlBarButton
          icon={isFullscreen ? 'smallscreen' : 'fullscreen'}
          className={cn(style.button, style.fullscreen)}
          onClick={changeFullscreenModeHandler}
        />
      </div>
    </div>
  );
};

export default RecorderVideoControlBar;
