import style from './index.module.scss';

const UIModal = (props) => {
  const { children } = props;

  return (
    <div className={style.container}>
      <div className={style.content}>{children}</div>
    </div>
  );
};

export default UIModal;
