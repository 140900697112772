import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';

import RecorderButton from '../Button';
import UISelect from '../../UI/Select';

import settingsImg from '../../../assets/img/settings.svg';
import {
  DEFAULT_DEVICE,
  getDevices,
  initStream,
  stopTracks,
} from '../../../utils';

import style from './index.module.scss';

const RecorderSetting = (props) => {
  const { curDevide, setCurDevice, disabled } = props;

  const [micDevices, setMicDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);

  const [isShowSelectDevices, setShowSelectDevices] = useState(false);

  const [audioDevice, setAudioDevice] = useState(DEFAULT_DEVICE);
  const [videoDevice, setVideoDevice] = useState(DEFAULT_DEVICE);

  const updateDevices = async () => {
    const devices = await getDevices();
    const micDevices = devices.filter((device) => device.kind === 'audioinput');
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput',
    );

    setMicDevices(micDevices);
    setVideoDevices(videoDevices);

    return {
      micDevices,
      videoDevices,
    };
  };

  const initDevices = async () => {
    const stream = await initStream({ audio: true, video: true });
    stopTracks(stream);

    const { micDevices, videoDevices } = await updateDevices();

    const initDevice = {
      audio: micDevices.length ? micDevices[0] : DEFAULT_DEVICE,
      video: videoDevices.length ? videoDevices[0] : DEFAULT_DEVICE,
    };

    setCurDevice(initDevice);
  };

  useEffect(() => {
    initDevices().then(() => {
      navigator.mediaDevices.ondevicechange = updateDevices;
    });

    return () => {
      navigator.mediaDevices.ondevicechange = undefined;
    };
  }, []);

  useEffect(() => {
    const { audio, video } = curDevide || {};
    setAudioDevice(audio || DEFAULT_DEVICE);
    setVideoDevice(video || DEFAULT_DEVICE);
  }, [curDevide]);

  const handlerSave = useCallback(() => {
    setCurDevice({
      audio: audioDevice,
      video: videoDevice,
    });

    setShowSelectDevices(false);
  }, [audioDevice, setCurDevice, videoDevice]);

  const wrapperClickHandler = () => {
    setShowSelectDevices(false);
  };

  return (
    <div className={cn('camera__controls', style.container)}>
      <RecorderButton
        key="Settings"
        name="Settings"
        imageSrc={settingsImg}
        onClick={() => setShowSelectDevices(!isShowSelectDevices)}
        disabled={disabled}
      />
      {isShowSelectDevices && (
        <div className={style.box}>
          <div className={style.wrapper} onClick={wrapperClickHandler} />
          <div className={style.box_container}>
            <div className={style.box_container__title}>Camera</div>
            <UISelect
              curValue={videoDevice}
              options={videoDevices}
              setValue={setVideoDevice}
              defaultValue={DEFAULT_DEVICE}
              className={style.box_container__select}
            />
          </div>

          <div className={style.box_container}>
            <div className={style.box_container__title}>Microphone</div>
            <UISelect
              curValue={audioDevice}
              options={micDevices}
              setValue={setAudioDevice}
              defaultValue={DEFAULT_DEVICE}
              className={style.box_container__select}
            />
          </div>

          <button onClick={handlerSave} className={style.box_btn}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default RecorderSetting;
