import { useRef } from 'react';

import UISlider from '../../../../UI/Slider';

const RecorderVideoControlBarSlider = (props) => {
  const { onChange, endTime, currentTime } = props;

  const sliderRef = useRef();

  return (
    <UISlider
      ref={sliderRef}
      onChange={onChange}
      min={0}
      name="interval"
      step={1}
      max={endTime}
      value={currentTime}
    />
  );
};
export default RecorderVideoControlBarSlider;
