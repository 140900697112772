import { base64StringToBlob, blobToBase64String } from 'blob-util';

const saveTumbnails = async (tumbnails) => {
  const data = await Promise.all(
    tumbnails.map((blob) => blobToBase64String(blob)),
  );

  localStorage.setItem('tumbnails', JSON.stringify(data));
};

const getTumbnails = () => {
  const data = JSON.parse(localStorage.getItem('tumbnails'));

  return data.map((elem) => base64StringToBlob(elem, 'image/jpeg'));
};

export default (() => ({
  saveTumbnails,
  getTumbnails,
}))();
