import style from './index.module.scss';

const UIQuestion = (props) => {
  const { handlerApprove, handlerCancel, children } = props;

  return (
    <div className={style.container}>
      <div className={style.text}>{children}</div>
      <div className={style.controls}>
        <button className={style.approve} onClick={handlerApprove}>
          YES
        </button>
        <button className={style.cancel} onClick={handlerCancel}>
          NO
        </button>
      </div>
    </div>
  );
};

export default UIQuestion;
