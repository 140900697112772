import { useEffect, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
import Microphone from 'wavesurfer.js/src/plugin/microphone/index';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';

import style from './index.module.scss';

const UIWave = (props) => {
  const { source, isPlay, currentTime } = props;

  const [wavesurfer, setWavesurfer] = useState();

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.setCurrentTime(currentTime);
      if (isPlay) {
        wavesurfer.play();
      } else {
        wavesurfer.pause();
      }
    }
  }, [currentTime, wavesurfer, isPlay]);

  useEffect(() => {
    const audioContext = new AudioContext();
    const processor = audioContext.createScriptProcessor(1024, 1, 1);

    const wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#FF8701',
      progressColor: '#FF1B1A',
      interact: false,
      cursorWidth: 0,
      audioContext: audioContext,
      audioScriptProcessor: processor,
      backgroundColor: 'black',
      closeAudioContext: true,
      barWidth: 2,
      plugins: [
        source
          ? CursorPlugin.create({
              showTime: true,
              opacity: 1,
              customShowTimeStyle: {
                'background-color': 'red',
                color: '#fff',
                padding: '2px',
                'font-size': '10px',
              },
            })
          : Microphone.create({
              bufferSize: 4096,
              numberOfInputChannels: 1,
              numberOfOutputChannels: 1,
              constraints: {
                video: false,
                audio: true,
              },
            }),
      ],
    });

    if (source) {
      wavesurfer.loadBlob(source);
      wavesurfer.on('ready', function () {
        wavesurfer.play();
      });
    } else {
      wavesurfer.microphone.start();
    }

    wavesurfer.setMute(true);

    setWavesurfer(wavesurfer);

    return () => {
      wavesurfer.destroy();
      setWavesurfer();
    };
  }, [source]);

  return <div id="waveform" className={style.container} />;
};

export default UIWave;
