import { format } from 'date-fns';
import {
  AUDIO_MODE,
  BASE_STREAM_CONFIG,
  IS_MOBILE,
  LOGO_K,
  MERGER_CONFIG,
  TUMBNAIL_COUNT,
  TUMBNAIL_STEP,
} from './contants';

export const getFileName = (id, type = 'video') => {
  return `${format(new Date(), 'yyyy-MM-dd-HHmmSS')}${id}.${
    type === 'audio' ? 'wav' : 'mp4'
  }`;
};

export const initStream = async (options) => {
  return navigator.mediaDevices.getUserMedia(options);
};

export const getDevices = async () => {
  return navigator.mediaDevices.enumerateDevices();
};

export const stopTracks = (stream) => {
  stream?.getTracks().forEach(stopTrack);
};

export const stopTrack = (track) => {
  track.stop();
};

export const getBaseConfig = (refVideo, isActiveScreenShare) => {
  const baseConfig = {
    width: MERGER_CONFIG.width,
    height: MERGER_CONFIG.height,
  };

  if (!isActiveScreenShare && refVideo.current) {
    const parentElement = refVideo.current.parentNode?.parentElement;

    if (parentElement) {
      baseConfig.height = parentElement.clientHeight;
      baseConfig.width = parentElement.clientWidth;
    }
  }

  return baseConfig;
};

export const getOrientation = () => {
  return window.orientation === 90 || window.orientation === -90 || !IS_MOBILE
    ? 'landscape'
    : 'portrait';
};

export const mirrorConfig = (streamConfig, y = 0, isSubVideo) => ({
  ...BASE_STREAM_CONFIG,
  draw: (ctx, frame, done) => {
    // You can do whatever you want with this canvas context
    ctx.save();

    if (isSubVideo) {
      const minSize = Math.min(streamConfig.width, streamConfig.height);
      const r = minSize / 2;

      const delta = Math.max(streamConfig.width, streamConfig.height) / 2 - r;
      const margin = 20;

      const dx =
        streamConfig.width -
        (minSize === streamConfig.width ? 0 : delta) +
        margin;

      const dy = (minSize === streamConfig.height ? 0 : -delta) + y - margin;

      ctx.beginPath();
      ctx.arc(
        r + margin,
        dy + streamConfig.height / 2,
        r,
        0,
        Math.PI * 2,
        true,
      ); // create an circle centered around frame
      ctx.closePath();
      ctx.clip(); // clip the context to the circle

      ctx.scale(-1, 1);
      ctx.drawImage(frame, -dx, dy, streamConfig.width, streamConfig.height);
    } else {
      ctx.scale(-1, 1);
      ctx.drawImage(
        frame,
        -streamConfig.width,
        y,
        streamConfig.width,
        streamConfig.height,
      );
    }

    ctx.restore();

    done();
  },
});

export const logoConfig = (_streamConfig, logo) => ({
  ...BASE_STREAM_CONFIG,
  index: 999999999,
  draw: (ctx, frame, done) => {
    // You can do whatever you want with this canvas context
    ctx.save();

    const k = logo.height === 0 ? 1 : logo.width / logo.height;

    let height;
    let width;

    if (logo.width < logo.height) {
      height = ctx.canvas.height * LOGO_K;
      width = height / k;
    } else {
      width = ctx.canvas.width * LOGO_K;
      height = width / k;
    }

    ctx.drawImage(logo, 10, 10, width, height);
    ctx.restore();
    done();
  },
});

export const getScreenShare = async () => {
  return navigator.mediaDevices.getDisplayMedia();
};

export const getTypeByMode = (mode) => {
  return mode === AUDIO_MODE ? 'audio' : 'video';
};

export const getTumbnailBlob = (video) => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext('2d')
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        function (blob) {
          return resolve(blob);
        },
        'image/jpeg',
        1,
      );
    } catch (err) {
      reject(err);
    }
  });
};

export const createTumbnail = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const url = URL.createObjectURL(file);
      const video = document.createElement('video');

      const tumbnails = [];
      let i = 0;

      const timeupdate = async () => {
        if (i < TUMBNAIL_COUNT) {
          const blob = await getTumbnailBlob(video);

          tumbnails.push(blob);

          video.currentTime += TUMBNAIL_STEP;
          i++;
        } else {
          return resolve(tumbnails);
        }
      };

      const initTimeupdate = async () => {
        if (video.duration === Infinity) {
          return;
        }

        video.currentTime = 1e101;
        video.removeEventListener('timeupdate', initTimeupdate);
        video.addEventListener('timeupdate', timeupdate);
        video.currentTime = 1;
      };

      video.addEventListener('loadeddata', initTimeupdate);
      video.addEventListener('timeupdate', initTimeupdate);

      video.preload = 'metadata';
      video.src = url;
      // Load video in Safari / IE11
      video.muted = true;
      video.playsInline = true;
      video.play();
    } catch (err) {
      return reject(err);
    }
  });
};

export const getTimeValue = (val) => {
  const seconds = val % 60;

  const minutes = (val - seconds) / 60;

  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};
