import cn from 'classnames';
import { useState } from 'react';

import style from './index.module.scss';

import UISelectOption from './Option';

const UISelect = (props) => {
  const { options, setValue, className, curValue, defaultValue } = props;

  const [isOpenSelect, setOpenSelect] = useState(false);

  const handlerClick = (deviceId) => {
    if (deviceId === curValue.deviceId) {
      setValue(defaultValue);
    } else {
      setValue(options.find((option) => option.deviceId === deviceId));
    }
  };

  const renderList = (options) => {
    return options.length
      ? options.map(({ deviceId, label }) => (
          <UISelectOption
            key={deviceId}
            deviceId={deviceId}
            isSelected={curValue.deviceId === deviceId}
            label={label}
            onClick={() => handlerClick(deviceId)}
          />
        ))
      : 'devices did not find';
  };

  return (
    <div
      className={cn(
        style.container,
        isOpenSelect && style.container_open,
        className,
      )}
      onClick={() => setOpenSelect(!isOpenSelect)}
    >
      <div className={style.container_title}>
        {curValue?.label || defaultValue.label}

        <span
          className={cn(
            style.container_title__carpet,
            isOpenSelect && style.container_title__carpet_open,
          )}
        />
      </div>

      <div className={style.container_options}>
        {isOpenSelect && renderList(options)}
      </div>
    </div>
  );
};

export default UISelect;
