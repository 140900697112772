export const CINCOPA_API_URL = process.env.REACT_APP_CINCOPA_API_URL;

export const AUDIO_MODE = 'audio';
export const CAMERA_MODE = 'camera';
export const CAMERA_SCREEN_MODE = 'cameraScreen';
export const SCREEN_MODE = 'screen';

export const DEFAULT_DEVICE = {
  deviceId: 'disable',
  label: 'disable',
};

export const RECORD_EVENT = {
  VIDEO_RECORDED: 'VIDEO_RECORDED',
  AUDIO_RECORDED: 'AUDIO_RECORDED',
  VIDEO_UPLOADED: 'VIDEO_UPLOADED',
  AUDIO_UPLOADED: 'AUDIO_UPLOADED',
  VIDEO_UPLOAD_FAILED: 'VIDEO_UPLOAD_FAILED',
  AUDIO_UPLOAD_FAILED: 'AUDIO_UPLOAD_FAILED',
  RECORD_FAILED: 'RECORD_FAILED',
  RECORD_CANCELLED: 'RECORD_CANCELLED',
  RECORD_RELOAD_STREAM: 'RECORD_RELOAD_STREAM',
  RECORD_ACTIVE: 'RECORD_ACTIVE',
  TUMBNAILS_CREATED: 'TUMBNAILS_CREATED',
  TUMBNAILS_CREATE_FAILED: 'TUMBNAILS_CREATE_FAILED',
  ASSET_PLAYBACK_STARTED: 'ASSET_PLAYBACK_STARTED',
  ASSET_PLAYBACK_PAUSED: 'ASSET_PLAYBACK_PAUSED',
  ASSET_PLAYBACK_FAILED: 'ASSET_PLAYBACK_FAILED',
  COMPLEATED: 'COMPLEATED',
};

export const MERGER_CONFIG = {
  height: 720,
  width: 1280,
  // fps: 30,
};

export const IS_MOBILE = window.innerWidth <= 768;

export const BASE_STREAM_CONFIG = {
  x: 0, // position of the topleft corner
  y: 0,
  audioContext: null,
};

export const ONE_SECOND = 1000; // ms

export const TUMBNAIL_COUNT = 5;
export const TUMBNAIL_STEP = 10; // s

export const LOGO_K = 0.05;
