import { useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import UISlider from '../../../../UI/Slider';

import style from './index.module.scss';

const RecorderVideoControlBarSoundBar = (props) => {
  const { className, refVideo } = props;

  const icons = {
    mute: (
      <svg id="mute" viewBox="0 0 21 19">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0383 2.54764C13.1302 1.93369 12.1037 1.48094 11 1.23047V3.29047C11.5642 3.45837 12.0987 3.69563 12.5936 3.99237L14.0383 2.54764ZM11 8.76982L12.5421 7.22769C13.1419 7.99067 13.5 8.95168 13.5 10.0005C13.5 11.7705 12.48 13.2905 11 14.0205V8.76982ZM9 7.58594V2.00047L4 7.00047H0V13.0005H3.58547L9 7.58594ZM5.38468 14.3851L9 10.7698V18.0005L5.38468 14.3851ZM14.3191 5.45069L15.7351 4.03476C17.1445 5.62206 18 7.71112 18 10.0005C18 14.2805 15.01 17.8605 11 18.7705V16.7105C13.89 15.8505 16 13.1705 16 10.0005C16 8.26396 15.3668 6.6745 14.3191 5.45069Z"
          fill="#ffffff"
        />
        <rect
          x="18.7031"
          y="0.882812"
          width="2"
          height="23.5901"
          transform="rotate(45 18.7031 0.882812)"
          fill="#ffffff"
        />
      </svg>
    ),
    sound2: (
      <svg viewBox="0 0 18 18">
        <path
          d="M0.5 5.77V11.77H4.5L9.5 16.77V0.77L4.5 5.77H0.5ZM14 8.77C14 7 12.98 5.48 11.5 4.74V12.79C12.98 12.06 14 10.54 14 8.77ZM11.5 0V2.06C14.39 2.92 16.5 5.6 16.5 8.77C16.5 11.94 14.39 14.62 11.5 15.48V17.54C15.51 16.63 18.5 13.05 18.5 8.77C18.5 4.49 15.51 0.91 11.5 0Z"
          fill="#ffffff"
        />
      </svg>
    ),
    sound1: (
      <svg viewBox="0 -1 18 18">
        <path
          d="M0 5V11H4L9 16V0L4 5H0ZM13.5 8C13.5 6.23 12.48 4.71 11 3.97V12.02C12.48 11.29 13.5 9.77 13.5 8Z"
          fill="#ffffff"
        />
      </svg>
    ),
    sound0: (
      <svg viewBox="0 -1 18 18">
        <path d="M0 5V11H4L9 16V0L4 5H0Z" fill="#ffffff" />
      </svg>
    ),
  };

  const sliderRef = useRef();

  const [isMute, setMute] = useState(false);
  const [volume, setVolume] = useState(1);

  const getSoundLevel = (item) => {
    if (item <= 0.3) {
      return 0;
    }
    if (item <= 0.7) {
      return 1;
    }

    return 2;
  };

  const muteHandler = useCallback(() => {
    const newMuteStatus = !isMute;
    setMute(newMuteStatus);

    if (refVideo?.current) {
      refVideo.current.muted = newMuteStatus;
    }
  }, [isMute, refVideo]);

  const volumeHandler = useCallback(
    (_event, value) => {
      setVolume(value);

      if (refVideo.current) {
        refVideo.current.volume = value;
      }
    },
    [refVideo],
  );

  return (
    <div className={cn(style.container, className)}>
      <div className={style.icon} onClick={muteHandler}>
        {icons[isMute ? 'mute' : `sound${getSoundLevel(volume)}`]}
      </div>
      <div className={style.player_sound_bar}>
        <UISlider
          ref={sliderRef}
          onChange={volumeHandler}
          min={0}
          step={0.001}
          max={1}
          value={volume}
        />
      </div>
    </div>
  );
};

export default RecorderVideoControlBarSoundBar;
