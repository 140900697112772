import smallCheckImg from '../../../assets/img/smallcheck.svg';

const RecorderButton = (props) => {
  const { name, imageSrc, onClick, isActive, disabled } = props;

  return (
    <button
      href="#"
      className="mr-4 md:mr-8 hover:opacity-70 transition-opacity turn__camera relative"
      onClick={onClick}
      disabled={disabled}
    >
      {isActive && (
        <span className="inline-flex items-center justify-center">
          <img src={smallCheckImg} alt="smallcheck" />
        </span>
      )}
      <img src={imageSrc} alt={name} />
      <span className="name">{name}</span>
    </button>
  );
};

export default RecorderButton;
