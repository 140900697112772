import UIModal from '../../../UI/Modal';
import UIQuestion from '../../../UI/Question';

import style from './index.module.scss';

const RecorderVideoRedoModal = (props) => {
  return (
    <UIModal>
      <UIQuestion {...props}>
        <div className={style.title}>
          Are you sure that you want to cancel and re-record your video?
        </div>
        <div className={style.description}>
          If you click yes, your current video will be completely removed and
          you will have to start again, do you want to procees?
        </div>
      </UIQuestion>
    </UIModal>
  );
};

export default RecorderVideoRedoModal;
