import cn from 'classnames';

import style from './index.module.scss';

const icons = {
  rec: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#CC3334" />
    </svg>
  ),
  redo: (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C16.5858 0 19.8153 1.46575 22.1639 3.8155L24.8506 1.12883L26 9.08158L17.9433 8.03508L20.6397 5.33867C18.681 3.38108 15.9824 2.16667 13 2.16667C7.0265 2.16667 2.16667 7.0265 2.16667 13C2.16667 18.9735 7.0265 23.8333 13 23.8333C17.1947 23.8333 20.8314 21.4327 22.6308 17.9368L24.4909 19.0699C22.3113 23.1898 17.9855 26 13 26C5.82075 26 0 20.1793 0 13C0 5.82075 5.82075 0 13 0Z"
        fill="#CC3334"
      />
    </svg>
  ),
  stop: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="black" />
    </svg>
  ),
  pause: (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="4" height="20" rx="2" fill="black" />
      <rect x="8" width="4" height="20" rx="2" fill="black" />
    </svg>
  ),
  play: (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.62003V18.38C0 19.658 1.3721 20.4345 2.42878 19.7389L15.2666 11.3589C16.2445 10.728 16.2445 9.27201 15.2666 8.62491L2.42878 0.261113C1.3721 -0.434522 0 0.342001 0 1.62003Z"
        fill="black"
      />
    </svg>
  ),
};

const RecorderVideoButton = (props) => {
  const { name, className, icon, onClick, isSettings } = props;

  return (
    <button onClick={onClick} className={cn(style.container, className)}>
      {(icon && icons[icon] && (
        <div className={style.icon}>{icons[icon]}</div>
      )) || <img className={style.icon} src={icon} alt={`${name}-icon`} />}

      {isSettings && (
        <div className={style.setting_block}>
          <svg
            width="5"
            height="5"
            viewBox="0 0 5 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M4.98428 2.21094L4.96866 2.09375L4.29678 1.97656C4.28116 1.90625 4.25772 1.84375 4.22647 1.78125C4.20303 1.71875 4.17178 1.65625 4.13272 1.59375L4.52335 1.03906L4.45303 0.945313C4.33585 0.796875 4.19522 0.65625 4.04678 0.539063L3.96085 0.46875L3.40616 0.859375C3.28116 0.789063 3.15616 0.742188 3.02335 0.703125L2.90616 0.03125L2.78897 0.015625C2.59366 -0.0078125 2.39835 -0.0078125 2.21085 0.015625L2.09366 0.03125L1.97647 0.703125C1.90616 0.71875 1.84366 0.742188 1.78116 0.773438C1.71866 0.796875 1.65616 0.828125 1.59366 0.867188L1.03897 0.476563L0.945221 0.546875C0.796783 0.664063 0.656158 0.804688 0.538971 0.953125L0.468658 1.03906L0.859283 1.59375C0.788971 1.71875 0.742096 1.84375 0.703033 1.97656L0.0311584 2.09375L0.0155334 2.21094C-0.00790405 2.40625 -0.00790405 2.60156 0.0155334 2.78906L0.0311584 2.90625L0.703033 3.02344C0.718658 3.09375 0.742096 3.15625 0.773346 3.21875C0.796783 3.28125 0.828033 3.34375 0.867096 3.40625L0.476471 3.96094L0.546783 4.05469C0.663971 4.20313 0.804596 4.34375 0.953033 4.46094L1.04678 4.53125L1.60147 4.14063C1.72647 4.21094 1.85147 4.25781 1.98428 4.29688L2.10147 4.96875L2.21866 4.98438C2.31241 4.99219 2.41397 5 2.50772 5C2.60147 5 2.70303 4.99219 2.79678 4.98438L2.91397 4.96875L3.03116 4.29688C3.10147 4.28125 3.16397 4.25781 3.22647 4.22656C3.28897 4.20313 3.35147 4.17188 3.41397 4.13281L3.96866 4.52344L4.06241 4.45313C4.21085 4.33594 4.35147 4.19531 4.46866 4.04688L4.53897 3.95313L4.14835 3.39844C4.21866 3.27344 4.26553 3.14844 4.3046 3.01563L4.97647 2.89844L4.9921 2.78125C5.00772 2.60156 5.00772 2.40625 4.98428 2.21094ZM2.49991 3.4375C1.98428 3.4375 1.56241 3.01563 1.56241 2.5C1.56241 1.98438 1.98428 1.5625 2.49991 1.5625C3.01553 1.5625 3.43741 1.98438 3.43741 2.5C3.43741 3.01563 3.01553 3.4375 2.49991 3.4375Z"
                fill="#000000"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="5" height="5" fill="#000000" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}

      <div className={style.name}>{name}</div>
    </button>
  );
};

export default RecorderVideoButton;
