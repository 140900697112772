import { memo, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import audioOnlyImg from '../../assets/img/audio.svg';
import camOnlyImg from '../../assets/img/camera.svg';
import camScreenImg from '../../assets/img/camerascreenlinear.svg';
import screenOnlyImg from '../../assets/img/screen.svg';
import uploadImg from '../../assets/img/upload.svg';

import RecorderVideo from './Video';
import RecorderButton from './Button';
import {
  AUDIO_MODE,
  CAMERA_MODE,
  CAMERA_SCREEN_MODE,
  createTumbnail,
  RECORD_EVENT,
  getFileName,
  getTypeByMode,
  initStream,
  SCREEN_MODE,
  stopTracks,
  IS_MOBILE,
} from '../../utils';
import RecorderSetting from './Setting';
import { sendFile } from '../../services/api.service';
import RecorderUnsupported from './Unsupported';
import UploadButton from './UploadButton';
import { StorageService } from '../../services';

import style from './index.module.scss';

const Recorder = (props) => {
  const {
    className,
    onSubscribe = () => {},
    visibility: { sendButton, screenShareButton },
    responseParameters,
  } = props;

  const isSupportRecorder = !!window.MediaRecorder;

  const refFile = useRef();

  const [mode, setMode] = useState(CAMERA_MODE);

  const [stream, setStream] = useState();

  const [curDevide, setCurDevice] = useState();

  const [file, setFile] = useState();

  const [isLoading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCreatingTumbnails, setCreatingTumbnails] = useState(false);

  const [isDisabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    return () => {
      stopTracks(stream);
    };
  }, [stream]);

  const changeStream = async (device) => {
    if (!device) {
      return;
    }

    const { audio, video } = device;
    const newStream = await initStream({
      audio:
        audio?.deviceId !== 'disable'
          ? {
              deviceId: { exact: audio?.deviceId },
            }
          : true,
      video: video?.deviceId !== 'disable' && {
        deviceId: { exact: video?.deviceId },
        facingMode: { ideal: 'environment' },
      },
    });

    if (audio?.deviceId === 'disable') {
      newStream?.getAudioTracks().forEach((track) => (track.enabled = false));
    }

    setStream(newStream);
  };

  const handleEvent = useCallback(
    async (event, data) => {
      switch (event) {
        case RECORD_EVENT.RECORD_RELOAD_STREAM:
          await stopTracks(stream);

          await changeStream(curDevide);
          break;
        case RECORD_EVENT.RECORD_ACTIVE:
          setDisabledBtn(true);
          break;
        case RECORD_EVENT.RECORD_FAILED:
        case RECORD_EVENT.AUDIO_RECORDED:
        case RECORD_EVENT.VIDEO_RECORDED:
          setDisabledBtn(false);
          break;
        case RECORD_EVENT.ASSET_PLAYBACK_FAILED:
          if (!responseParameters.assetId) {
            return;
          }
          break;
        default:
          break;
      }

      onSubscribe &&
        onSubscribe(event, {
          ...responseParameters,
          assetType: mode === AUDIO_MODE ? 'audio' : 'video',
          ...data,
        });
    },
    [curDevide, mode, onSubscribe, responseParameters, stream],
  );

  useEffect(() => {
    if (mode === AUDIO_MODE) {
      changeStream({ audio: curDevide.audio, video: false });
    } else {
      changeStream(curDevide);
    }
  }, [curDevide, mode]);

  const uploadFileHandler = () => {
    refFile?.current?.click();
  };

  const uploadHandler = useCallback(async () => {
    if (!file) {
      return;
    }

    try {
      setLoading(true);
      setDisabledBtn(true);

      const fileName = getFileName(
        responseParameters.userId,
        getTypeByMode(mode),
      );

      await sendFile({ file, fileName, setProgress });

      setProgress(100);

      onSubscribe(
        mode === AUDIO_MODE
          ? RECORD_EVENT.AUDIO_UPLOADED
          : RECORD_EVENT.VIDEO_UPLOADED,
        { ...responseParameters },
      );

      try {
        if (mode !== AUDIO_MODE) {
          setCreatingTumbnails(true);
          const tumbnails = await createTumbnail(file);
          await StorageService.saveTumbnails(tumbnails);
          onSubscribe(RECORD_EVENT.TUMBNAILS_CREATED, {
            ...responseParameters,
            tumbnails,
          });
        }
      } catch (error) {
        onSubscribe(RECORD_EVENT.TUMBNAILS_CREATE_FAILED, {
          ...responseParameters,
          error,
        });
      } finally {
        setCreatingTumbnails(false);
      }
    } catch (error) {
      onSubscribe(
        mode === AUDIO_MODE
          ? RECORD_EVENT.AUDIO_UPLOAD_FAILED
          : RECORD_EVENT.VIDEO_UPLOAD_FAILED,
        { ...responseParameters, error },
      );
    } finally {
      setProgress(0);
      setLoading(false);
      setDisabledBtn(false);
      onSubscribe(RECORD_EVENT.COMPLEATED, responseParameters);
    }
  }, [
    file,
    mode,
    onSubscribe,
    setProgress,
    setDisabledBtn,
    setCreatingTumbnails,
    responseParameters,
  ]);

  const fileChangeHandle = async (event) => {
    if (event.target?.files) {
      const fileUploaded = event.target.files[0];

      setFile(fileUploaded);

      event.target.value = null;
    }
  };

  const getBtnText = (props) => {
    const { file, isCreatingTumbnails } = props;

    if (isCreatingTumbnails) {
      return 'Creating tumbnails...';
    }

    return file instanceof File ? 'Next & Upload' : 'Submit Recording';
  };

  return (
    <div className={cn('outer__camera mx-auto', style.container, className)}>
      {isSupportRecorder ? (
        <>
          <RecorderVideo
            {...props}
            onSubscribe={handleEvent}
            mode={mode}
            stream={stream}
            file={file}
            setFile={setFile}
          />
          <div
            className={cn(
              'camera__controls flex items-center justify-center py-4',
              style.controls,
            )}
          >
            <RecorderButton
              key="Cam only"
              name="Cam only"
              imageSrc={camOnlyImg}
              onClick={() => setMode(CAMERA_MODE)}
              isActive={mode === CAMERA_MODE}
              disabled={isDisabledBtn}
            />
            {!IS_MOBILE && screenShareButton && (
              <>
                <RecorderButton
                  key="Cam + Screen"
                  name="Cam + Screen"
                  imageSrc={camScreenImg}
                  onClick={() => setMode(CAMERA_SCREEN_MODE)}
                  isActive={mode === CAMERA_SCREEN_MODE}
                  disabled={isDisabledBtn}
                />
                <RecorderButton
                  key="Screen only"
                  name="Screen only"
                  imageSrc={screenOnlyImg}
                  onClick={() => setMode(SCREEN_MODE)}
                  disabled={isDisabledBtn}
                  isActive={mode === SCREEN_MODE}
                />
              </>
            )}

            <RecorderButton
              key="Audio only"
              name="Audio only"
              imageSrc={audioOnlyImg}
              onClick={() => setMode(AUDIO_MODE)}
              isActive={mode === AUDIO_MODE}
              disabled={isDisabledBtn}
            />

            <RecorderSetting
              curDevide={curDevide}
              setCurDevice={setCurDevice}
              disabled={isDisabledBtn}
            />
            {sendButton && (
              <>
                <RecorderButton
                  key="Upload"
                  name="Upload"
                  imageSrc={uploadImg}
                  disabled={isDisabledBtn}
                  onClick={uploadFileHandler}
                />
                <input
                  type="file"
                  ref={refFile}
                  accept={`${getTypeByMode(mode)}/*`}
                  onChange={fileChangeHandle}
                  style={{ display: 'none' }}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <RecorderUnsupported {...props} setLoading={setLoading} />
      )}

      <div className="camera__next flex flex-col justify-center items-center md:flex-row md:items-center md:justify-between pt-4">
        <a
          href="#"
          className="m-0 mb-3 md:mb-0 medium--text hover:opacity-70 transition-opacity"
        >
          Test video configuration
        </a>
        <UploadButton
          onClick={uploadHandler}
          isLoading={isLoading}
          progress={progress}
        >
          {getBtnText({ file, isCreatingTumbnails })}
        </UploadButton>
      </div>
    </div>
  );
};

export default memo(Recorder);
