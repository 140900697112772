import cn from 'classnames';

import style from './index.module.scss';

const RecorderVideoTimer = (props) => {
  const { isTimerActive, timer } = props;

  return (
    isTimerActive && (
      <div className={cn(style.time_out_timer, timer !== 0 && style.animation)}>
        {timer === 0 ? 'Action!' : timer}
      </div>
    )
  );
};

export default RecorderVideoTimer;
