import { getTimeValue } from '../../../../../utils';

import style from './index.module.scss';

const RecorderVideoControlBarTime = (props) => {
  const { currentTime, endTime } = props;

  return (
    <div className={style.container}>
      <span className={style.current_time}>{getTimeValue(currentTime)}</span>
      {endTime !== Infinity && (
        <>
          &nbsp;/&nbsp;
          <span className={style.duration}>{getTimeValue(endTime)}</span>
        </>
      )}
    </div>
  );
};

export default RecorderVideoControlBarTime;
