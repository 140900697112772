import { useRef } from 'react';

import cn from 'classnames';

import uploadImg from '../../../assets/img/upload.svg';

import { sendFile, StorageService } from '../../../services';
import {
  createTumbnail,
  getFileName,
  getTypeByMode,
  RECORD_EVENT,
} from '../../../utils';
import RecorderButton from '../Button';

import style from './index.module.scss';

const RecorderUnsupported = (props) => {
  const {
    branding: { logoImageUrl },
    responseParams: { recordId },
    visibility: { sendButton },
    onSubscribe,
    setLoading,
  } = props;

  const refFile = useRef();

  const uploadHandler = async () => {
    refFile?.current?.click();
  };

  const fileChangeHandle = async (event) => {
    if (event.target?.files) {
      const file = event.target.files[0];

      const fileType = getTypeByMode(file.type.split('/')[0]);

      try {
        setLoading(true);

        const fileName = getFileName(recordId, fileType);

        await sendFile({ file, fileName });
        onSubscribe(
          fileType === 'audio'
            ? RECORD_EVENT.AUDIO_UPLOADED
            : RECORD_EVENT.VIDEO_UPLOADED,
          { recordId },
        );

        try {
          if (fileType === 'video') {
            const tumbnails = await createTumbnail(file);
            await StorageService.saveTumbnails(tumbnails);
            onSubscribe(RECORD_EVENT.TUMBNAILS_CREATED, { recordId });
          }
        } catch {
          onSubscribe(RECORD_EVENT.TUMBNAILS_CREATE_FAILED, { recordId });
        }
      } catch {
        onSubscribe(
          fileType === 'audio'
            ? RECORD_EVENT.AUDIO_UPLOAD_FAILED
            : RECORD_EVENT.VIDEO_UPLOAD_FAILED,
          { recordId },
        );
      } finally {
        setLoading(false);
      }

      event.target.value = null;
    }
  };

  return (
    <div className={cn(style.container, 'camera__controls')}>
      <img className={style.logo} alt="logo" src={logoImageUrl} />
      {sendButton ? (
        <>
          <div className={style.warning}>
            Sorry, this browser does not support VIDEO capture yet. We recommend
            using Chrome or Firefox instead, otherwise please upload a video
            file below ↓
          </div>
          <RecorderButton
            onClick={uploadHandler}
            key="Upload"
            name="Upload"
            imageSrc={uploadImg}
            className={style.upload_btn}
          />
          <input
            type="file"
            ref={refFile}
            accept="video/*"
            onChange={fileChangeHandle}
            style={{ display: 'none' }}
          />
        </>
      ) : (
        <div className={style.warning}>
          Sorry, this browser does not support VIDEO capture yet. We recommend
          using Chrome or Firefox instead
        </div>
      )}
    </div>
  );
};

export default RecorderUnsupported;
