import React, { useState } from 'react';

import './App.css';
import UIHeader from './components/UI/Header';
import ViewRecorder from './views/Recorder';

function App() {
  const [tumbmnails, setTumbnails] = useState([]);

  return (
    <>
      <UIHeader />
      <ViewRecorder setTumbnails={setTumbnails} />
      <div className="trash">
        {tumbmnails.map((elem, i) => (
          <img src={URL.createObjectURL(elem)} key={i} />
        ))}
      </div>
    </>
  );
}

export default App;
