import cn from 'classnames';

import style from './index.module.scss';

const RecorderVideoTime = (props) => {
  const { timeLeft, file, recStatus, stopTimer } = props;

  const getTimeWithMinutes = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    timeLeft &&
    !file && (
      <div className={style.timer}>
        <div
          className={cn(style.indicator, recStatus === 'rec' && style.rec)}
        />
        {getTimeWithMinutes(stopTimer)}
      </div>
    )
  );
};

export default RecorderVideoTime;
