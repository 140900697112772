import { useState } from 'react';

import style from './index.module.scss';

const UISelectOption = (props) => {
  const { label, isSelected, onClick } = props;

  const [isHover, setHover] = useState(false);

  const handlerHover = (event) => {
    if (event.type === 'mouseover') {
      setHover(true);
    } else if (event.type === 'mouseout') {
      setHover(false);
    }
  };

  return (
    <div
      className={style.container}
      onMouseOver={handlerHover}
      onMouseOut={handlerHover}
      onClick={onClick}
    >
      <div className={style.children}>
        <div>
          {isSelected || isHover ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#FFFFFF"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.16667 0.5H13.8333C14.75 0.5 15.5 1.25 15.5 2.16667V13.8333C15.5 14.75 14.75 15.5 13.8333 15.5H2.16667C1.25 15.5 0.5 14.75 0.5 13.8333V2.16667C0.5 1.25 1.25 0.5 2.16667 0.5ZM5.75001 11.575C6.07501 11.9 6.60001 11.9 6.92501 11.575L13.2417 5.25C13.5667 4.925 13.5667 4.4 13.2417 4.075C12.9167 3.75 12.3917 3.75 12.0667 4.075L6.33334 9.80834L3.93334 7.40833C3.60834 7.08333 3.08334 7.08333 2.75834 7.40833C2.60229 7.56403 2.5146 7.7754 2.5146 7.99583C2.5146 8.21627 2.60229 8.42764 2.75834 8.58333L5.75001 11.575Z"
                fill="#FFFFFF"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.16667 0.5H13.8333C14.75 0.5 15.5 1.25 15.5 2.16667V13.8333C15.5 14.75 14.75 15.5 13.8333 15.5H2.16667C1.25 15.5 0.5 14.75 0.5 13.8333V2.16667C0.5 1.25 1.25 0.5 2.16667 0.5ZM5.75001 11.575C6.07501 11.9 6.60001 11.9 6.92501 11.575L13.2417 5.25C13.5667 4.925 13.5667 4.4 13.2417 4.075C12.9167 3.75 12.3917 3.75 12.0667 4.075L6.33334 9.80834L3.93334 7.40833C3.60834 7.08333 3.08334 7.08333 2.75834 7.40833C2.60229 7.56403 2.5146 7.7754 2.5146 7.99583C2.5146 8.21627 2.60229 8.42764 2.75834 8.58333L5.75001 11.575Z"
                fill="white"
              />
              <rect x="2" y="3" width="12" height="10" fill="white" />
            </svg>
          )}
        </div>

        {label}
      </div>
    </div>
  );
};

export default UISelectOption;
