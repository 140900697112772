import cn from 'classnames';

import style from './index.module.scss';

const UploadButton = (props) => {
  const { onClick, children, isLoading, progress } = props;

  const percent = `${progress}%`;

  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className={cn(
        'flex justify-center items-center px-11 h-11 rounded cursor-pointer text-sm md:text-base hover:opacity-70 transition-opacity back--color',
        style.container,
        isLoading && style.loading,
      )}
    >
      <div
        className={cn(style.progress_bar, 'rounded')}
        style={{ width: percent }}
      />
      <span className="text-white">
        {isLoading ? `Uploading: ${percent}` : children}
      </span>
    </button>
  );
};

export default UploadButton;
